import { DirectusNode } from './sort.interface';

export function sort<T>(nodes: (T & DirectusNode)[]): (T & DirectusNode)[] {
  return nodes.sort((nodeA: any, nodeB: any) => {
    if (nodeA.node && nodeA.node.sort) {
      return nodeA.node.sort - nodeB.node.sort;
    }

    return nodeA.sort - nodeB.sort;
  });
}
