import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusFaqPanelQuestion, FaqPanelQuestionEdges } from './faq-panel.interface';

export default (): FaqPanelQuestionEdges[] => {
  const { allDirectusFaqPanelQuestion } = useStaticQuery<AllDirectusFaqPanelQuestion>(graphql`
    query {
      allDirectusFaqPanelQuestion(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            sort
            position
            slug
            title
            body
          }
        }
      }
    }
  `);

  return allDirectusFaqPanelQuestion.edges;
};
