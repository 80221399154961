// extracted by mini-css-extract-plugin
export var component__carousel = "CustomerReviews--component__carousel--7Vo6J";
export var component__link = "CustomerReviews--component__link--fXCWs";
export var component__link__big = "CustomerReviews--component__link__big--Eg5jm";
export var component__link__small = "CustomerReviews--component__link__small--9tHlS";
export var component__subtitle = "CustomerReviews--component__subtitle--ERhVr";
export var component__title = "CustomerReviews--component__title--H7raA";
export var glider = "CustomerReviews--glider--MzCxa";
export var gliderContain = "CustomerReviews--glider-contain--awZh6";
export var pane = "CustomerReviews--pane--epbma";
export var pane__image = "CustomerReviews--pane__image--wlrCp";
export var pane__image_container = "CustomerReviews--pane__image_container--aIvHn";
export var pane__ratings = "CustomerReviews--pane__ratings--ykRti";
export var pane__reviews = "CustomerReviews--pane__reviews--aFusA";
export var pane__stars = "CustomerReviews--pane__stars--Z5Ov5";
export var tkMyriadProSemiCondensed = "CustomerReviews--tk-myriad-pro-semi-condensed--GMit1";