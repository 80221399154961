import React from 'react';
import slugify from 'react-slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';

import Markdown from 'components/common/markdown/Markdown';
import FAQPageRichSnippet from 'components/common/rich-snippets/faq-page/FaqPage';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import CollapsibleList from 'components/directus/collapsible-list/CollapsibleList';
import faqPanelHook from 'hooks/faq-panel/faq-panel.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './FaqInfoPanel.interface';

import { panel, panel__ul, panel__item } from './FaqInfoPanel.scss';

export default ({ slug }: Props): JSX.Element => {
  const questions = faqPanelHook().filter(({ node }) => node.slug === slug);
  const { node } = getFieldBySlug('frequently-asked-questions', sectionTitleHook());
  const faqs = questions.map(({ node }) => ({
    question: node.title,
    answer: node.body,
  }));

  return (
    <>
      <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />

      <div className={panel}>
        <ul className={panel__ul}>
          {questions.map(
            ({ node }): JSX.Element => (
              <li className={panel__item} key={`faq-panel-${slugify(node.title)}`}>
                <CollapsibleList key={`faq-panel-${slugify(node.title)}`} openText={node.title} closeText={node.title}>
                  <Markdown
                    source={node.body}
                    container
                    listItemIcon={<FontAwesomeIcon icon={faChevronRight} size="sm" aria-label="check icon." />}
                  />
                </CollapsibleList>
              </li>
            )
          )}
        </ul>
      </div>
      <FAQPageRichSnippet faqs={faqs} />
    </>
  );
};
